import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, message } from 'antd'
import { navigateTo } from '../../tools/link'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { useAuthorizationContext } from './../../context/authorizationContext/AuthorizationContext'
import { EnumPageUrl } from '@enums'
import { navigate } from 'gatsby'

const LoginFormContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginForm = styled(Form)`
  width: 30rem;
`

const LoginFormTitle = styled.h2`
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 1.8rem;
  color: #495057;
`
const LoginFormSubmitButton = styled(Button)`
  width: 100%;
`

const LoginFormResetPasswordButton = styled(Button)`
  width: 100%;
`

const LoginInput = styled(Input)`
  height: 3.5rem;
`
const LoginContent = () => {
  const [form] = Form.useForm()
  const { signIn } = useAuthorizationContext()

  const [isLoading, showLoading] = useState<boolean>(false)
  const [isResetPasswordLoading, showResetPasswordLoading] =
    useState<boolean>(false)

  const login = async () => {
    showLoading(true)
    const formData = form.getFieldsValue()
    const result = await signIn(formData)
    if (result) {
      navigate(EnumPageUrl.OwnStorePage)
    } else {
      message.error('用户名或密码错误。')
    }
    showLoading(false)
  }

  const resetPassword = async () => {
    showResetPasswordLoading(true)
    const formData = form.getFieldsValue()
    const result = await signIn(formData)
    if (result) {
      navigateTo(EnumPageUrl.ResetPassword)
    } else {
      message.error('用户名或密码错误。')
    }
    showResetPasswordLoading(false)
  }

  return (
    <LoginFormContainer>
      <LoginForm name="login" form={form}>
        <LoginFormTitle>登录用户</LoginFormTitle>
        <Form.Item name="username">
          <LoginInput prefix={<UserOutlined />} placeholder="Username" />
        </Form.Item>
        <Form.Item name="password">
          <LoginInput
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <LoginFormSubmitButton
            type="primary"
            htmlType="submit"
            icon={isLoading && <LoadingOutlined />}
            onClick={login}
            disabled={isLoading}
          >
            {isLoading ? '正在登陆...' : '登 录'}
          </LoginFormSubmitButton>
        </Form.Item>
        <Form.Item>
          <LoginFormResetPasswordButton
            htmlType="submit"
            icon={isResetPasswordLoading && <LoadingOutlined />}
            onClick={resetPassword}
            disabled={isResetPasswordLoading}
          >
            {isResetPasswordLoading ? '正在登陆并跳转重置页...' : '重置密码'}
          </LoginFormResetPasswordButton>
        </Form.Item>
      </LoginForm>
    </LoginFormContainer>
  )
}

export default LoginContent
