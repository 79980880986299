import React from 'react'
import Header from '../component/tools/Header'
import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import LoginContent from '../component/tools/Login'

const OwnStorePageContainer = () => {
  return (
    <AuthorizationProvider>
      <Header />
      <LoginContent />
    </AuthorizationProvider>
  )
}
export default OwnStorePageContainer
